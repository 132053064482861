<template>
  <div class="documentation">
    <div class="content" v-html="formattedContent"></div>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  name: 'DocumentationView',
  data() {
    return {
      markdownContent: '',
    };
  },
  computed: {
    formattedContent() {
      return marked(this.markdownContent);
    }
  },
  async created() {
    try {
      const response = await fetch(`${this.$env.api}/data/get_documentation.php`);
      this.markdownContent = await response.text();
    } catch (error) {
      console.error('Error loading documentation:', error);
    }
  }
};
</script>

<style scoped>
.documentation {
  padding: 2rem;
}
.content {
  max-width: 1200px;
  margin: 0 auto;
}
.content :deep(pre) {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
}
.content :deep(code) {
  background-color: #f5f5f5;
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
}
</style>
