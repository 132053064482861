<template>

    <b-menu :activable="false" class="is-custom-mobile">
      <b-menu-list label="Manage Adverts">
        <b-menu-item
          icon="files"
          label="Adverts"
          @click="goTo('user-adverts')"
          :active="isActive == 'user-adverts'"
        ></b-menu-item>
        <b-menu-item
          icon="folder-grid"
          label="Archive"
          @click="goTo('adverts-archive')"
          :active="isActive == 'adverts-archive'"
        ></b-menu-item>
      </b-menu-list>
      <b-menu-list label="General">
        <b-menu-item
          icon="house-chimney"
          label="Dashboard"
          :active="isActive == 'dashboard'"
          @click="goTo('dashboard')"
        ></b-menu-item>
        <b-menu-item
          icon="file-lines"
          label="Documentation"
          :active="isActive == 'documentation'"
          @click="goTo('documentation')"
        ></b-menu-item>
        <b-menu-item
          icon="key"
          label="API Token"
          :active="isActive == 'api-token'"
          @click="goTo('api-token')"
        ></b-menu-item>
        <b-menu-item
          icon="arrow-right-from-bracket"
          label="Logout"
          @click="logout()"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
 
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserMenu",
  props: {
    activeElement: String,
  },
  data() {
    return {
      isActive: "dashboard"
    };
  },
  methods: {
    showModal(modal) {
      this.$store.commit("showModal", modal);
    },
    logout() {
      this.$store.commit("logoutUser");
      this.$router.push({ path: "/" }).catch((err) => {
        console.log(err);
      });
    },
    setActive(element) {
      this.isActive[element] = true;
    },
    goTo(path) {
      let curentPath = this.$route.path;
      curentPath = curentPath.replace("/", "");
      if (curentPath == path) {
        return;
      }
      this.$router.push({ path: "/"+path }).catch((err) => {
        console.log(err);
      });
      this.isActive = path;
    },
  },
  mounted() {
    //this.isActive[this.activeElement] = true;
    //console.log(this.isActive[this.activeElement]);
    //console.log(this.activeElement);
    let path = this.$route.path;
    path = path.replace("/", "");
    this.isActive = path;
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
<style scoped></style>
