import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      isLogged: false,
      token: null,
      name: null,
      email: null,
      status: null,
      type: null,
    },
    sidebarShow: true,
    categories: [],
    modals: {
      addStaff: false,
      editStaff: false,
      editStaffPass: false,
    },
    editingCategory: null,
  },
  getters: {},
  mutations: {
    showModal(state, payload) {
      console.log("showModal: " + payload);
      state.modals[payload] = true;
    },
    hideModal(state, payload) {
      state.modals[payload] = false;
    },
    toggleModal(state, payload) {
      state.modals[payload] = !state[payload];
    },
    toggleSidebar(state) {
      state.sidebarShow = !state.sidebarShow;
    },
    hideSidebar(state) {
      state.sidebarShow = false;
    },
    showSidebar(state) {
      state.sidebarShow = true;
    },
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.type = payload.type;
      //local
      localStorage.sxPartnerToken = payload.jwt;
      localStorage.sxEmail = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      //local
      localStorage.removeItem("sxAdminToken");
      localStorage.removeItem("sxEmail");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.sxPartnerToken;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.type = payload.type;
    },
    statusUser(state, status) {
      state.status = status;
    },
    setEdit(state, payload) {
      state[payload.type] = payload.id;
    },
    unsetEdit(state, payload) {
      state[payload.type] = null;
    },
    setCategories(state, payload) {
      //console.log("seting Categories");
      state.categories = [];
      state.categories = payload;
    },
  },
  actions: {
    loadCategories({ commit }) {
      //console.log("loading categories...");
      axios
        //.get(`https://proofs.displaydynamics.ca/adminapi/categories/get_categories.php?t=${new Date().getTime()}`)
        .get(`https://admin.salex.md/api/categories/get_categories.php?t=${new Date().getTime()}`)
        .then((response) => {
          //console.log("loaded Categories", response.data);
          commit("setCategories", response.data.categories);
        });
    },
  },
  modules: {},
});
