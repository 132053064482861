<template>
  <div class="dashboard-page">
    <div class="columns">
      <div class="column">
            <TotalAdverts :inData="totalItems" />
      </div>
      <!-- <div class="column">
        <ChartUsers :inData="totalItems.usersChart"/>
      </div> -->
      <!-- <div class="column">
        <ChartAdverts :categoriesData="totalItems.advertsChart"/>
      </div> -->
    </div>
    <!-- <div class="columns">
      <div class="column">
        <TotalAdvertsCategory v-for="(item,index) in convertedCatData" :inData="item" :key="index"/>
      </div>
      <div class="column">
        <ChartAdverts :categoriesData="totalItems.advertsChart"/>
      </div>
    </div> -->

  <div>
      <b-message type="is-info">
        <!--WELCOEME MESSAGE-->
        <h1> Welcome to Salex Partners Admin System</h1>
        <p> Please read the <a @click="openPage('documentation')">documentation</a> for more information on how to use this system and the <a @click="openURL(`${$env.siteUrl}/test/`)" target="_blank">JS Example</a> to get started with the API and the <a @click="downloadExample()" target="_blank">Example</a> to download the example zip file.</p>

        <ul class="help-list">
          <li>
            Use <a @click="openPage('user-adverts')"><strong>Adverts</strong></a> page to check your uploaded adverts.
          </li>
          <li>
            Your adverts will become live after some time. Only <strong>5 adverts</strong> will become live in a day. Please contact us for any question regarding this process.
          </li>
          <li>
            Please note that the API has a limit of <strong>60 requests</strong> per minute.
          </li>
          <li>
            Please note that this is a <strong>beta</strong> version of the system and may not be <strong>stable</strong>.
          </li>
        </ul>

        <p> For more information on how to use the API, please visit <a @click="openPage('documentation')">our API documentation</a>.</p>
      </b-message>
  </div>

  <div class="buttons mt-4">     
        <b-button @click="openPage('documentation')" class="button is-primary">
          Documentation
        </b-button>

        <b-button @click="openURL(`${$env.siteUrl}/test/`)" class="button is-primary">
          JS Example
        </b-button>

        <b-button @click="downloadExample()" class="button is-primary">
          Download Example
        </b-button>
    </div>

</div>
</template>

<script>

import TotalAdverts from "@/components/dashboard/TotalAdverts.vue";
//import ChartAdverts from "@/components/dashboard/ChartAdverts.vue";
//import TotalAdvertsCategory from "@/components/dashboard/TotalAdvertsCategory.vue";

export default {
  name: "DashboardView",
  metaInfo: {
    title: 'Dashboard | Salex Partners Admin System',
    htmlAttrs: {
      lang: 'en'
    }
  },
  data() {
    return {
      isLoading: false,
      totalItems: {
        activeAdverts: "0",
        totalAdverts: "0",      
        advertsChart:{}
      },
    };
  },
  components: {
    TotalAdverts,
    // ChartAdverts,
    // TotalAdvertsCategory,
  },
  methods: {
    openURL(url) {
      window.open(url, "_blank");
    },
    openPage(page){
      //vue router
      this.$router.push({ name: page });
    },
    downloadExample(){
      window.open(`${this.$env.siteUrl}/test/test-example.zip`, '_blank');
    },
    decodeHtmlEntity(key) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = key;
      return textarea.value;
    },
    loadDashboardData() {
        this.isLoading = true;
        this.axios
          .post(`${this.$env.api}/users/dashboard.php`, {
            token: localStorage.sxPartnerToken,
            action: "getDashboardData",
          })
          .then((response) => {
            console.log(response.data);
            this.isLoading = false;
            if (response.data.status) {
              this.totalItems.totalAdverts = response.data.data.total_adverts;
              this.totalItems.activeAdverts = response.data.data.live_adverts;
            } else {
              this.totalItems = { 
                  activeAdverts: "0",
                  totalAdverts: "0",
                };
              console.log("Error getting data");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.errored = true;
          });
        //.finally(() => (this.loading = false));
      },
  },
  mounted() {
    this.loadDashboardData();
  },
};
</script>

<style scoped>
.help-list {
  margin: 1rem 0;
  list-style: disc;
  margin-left: 1.5em;
}
</style>  
