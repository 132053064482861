<template>
  <div
    class="box box-blue dashboard-box"
    @click="$router.push('/user-adverts')"
  >
    <div class="level">
      <div class="el-color level-left is-size-4 is-bebas">
        <i class="el-color files mr-2"></i> Adverts
      </div>
      <div class="level-right">
        <div>
          <span class="el-color t-num">{{ inData.activeAdverts }}</span
          ><span class="t-total">/{{ inData.totalAdverts }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TotalAdverts",
  props: {
    inData: Object,
  },
};
</script>
