<template>
  <div class="api-token">
    <div class="card">
      <div class="card-content">
        <h1 class="title">API Token Management</h1>
        
        <div class="field" v-if="token">
          <b-field label="Your API Token">
            <b-input placeholder="Token..." v-model="token" type="text" expanded></b-input>
            <p class="control">
              <b-button class="button is-primary" icon-left="clone" @click="copyToken"/>
            </p>
          </b-field>
          <p class="help">Click the copy icon to copy the token to clipboard</p>
        </div>

        <div class="buttons mt-4">
          <b-button 
            type="is-primary" 
            icon-left="arrows-rotate"
            :loading="loading"
            @click="generateToken"
          >
            Generate New Token
          </b-button>
        </div>

        <article class="message is-info mt-4">
          <div class="message-body">
            <p><strong>Important Notes:</strong></p>
            <ul>
              <li>This token is valid for 1 year from generation</li>
              <li>Keep this token secure - it provides access to the API</li>
              <li>Generating a new token will invalidate the previous one</li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ApiTokenView',
  data() {
    return {
      token: '',
      loading: false
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async getToken() {
      try {
        this.loading = true;
        const response = await this.axios.post(`${this.$env.api}/users/api_token.php`, {
          action: 'getToken',
          token: localStorage.sxPartnerToken
        });
        
        console.log(response.data);

        if (response.data.success) {
          this.token = response.data.token;
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        this.$buefy.toast.open({
          message: 'Failed to fetch API token',
          type: 'is-danger'
        });
      } finally {
        this.loading = false;
      }
    },
    
    async generateToken() {
      try {
        this.loading = true;
        const response = await this.axios.post(`${this.$env.api}/users/api_token.php`, {
          action: 'genToken',
          token: localStorage.sxPartnerToken
        });

        console.log(response.data);
        
        if (response.data.success) {
          this.token = response.data.token;
          this.$buefy.toast.open({
            message: 'New API token generated successfully',
            type: 'is-success'
          });
        }
      } catch (error) {
        console.error('Error generating token:', error);
        this.$buefy.toast.open({
          message: 'Failed to generate new API token',
          type: 'is-danger'
        });
      } finally {
        this.loading = false;
      }
    },
    
    async copyToken() {
      try {
        await navigator.clipboard.writeText(this.token);
        this.$buefy.toast.open({
          message: 'Token copied to clipboard',
          type: 'is-success'
        });
      } catch (error) {
        console.error('Error copying token:', error);
        this.$buefy.toast.open({
          message: 'Failed to copy token',
          type: 'is-danger'
        });
      }
    }
  },
  created() {
    this.getToken();
  }
};
</script>

<style scoped>
.api-token {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.icon.is-clickable {
  cursor: pointer;
}

ul {
  list-style: disc;
  margin-left: 1.5em;
}
</style>
