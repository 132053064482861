import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=64a3d4b2&scoped=true"
import script from "./UserMenu.vue?vue&type=script&lang=js"
export * from "./UserMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a3d4b2",
  null
  
)

export default component.exports