import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import DashboardView from '../views/DashboardView.vue'
import DocumentationView from '../views/DocumentationView.vue'
import ApiTokenView from '../views/ApiTokenView.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/user-adverts/:page?",
    name: "user-adverts",
    component: () =>
      import(/* webpackChunkName: "adverts-list" */ "../views/AdvertsView.vue"),
  },
  {
    path: "/adverts-archive/:page?",
    name: "adverts-archive",
    component: () =>
      import(/* webpackChunkName: "adverts-archive" */ "../views/ArchiveView.vue"),
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: DocumentationView,
    meta: { requiresAuth: true }
  },
  {
    path: '/api-token',
    name: 'api-token',
    component: ApiTokenView,
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
